var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dn-loop-table__container" }, [
    _c("table", { staticClass: "dn-loop-table" }, [
      _c("thead", [
        _c(
          "tr",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.$vuetify.breakpoint.mdAndUp,
                expression: "$vuetify.breakpoint.mdAndUp",
              },
            ],
          },
          [
            _c("th"),
            _c("th", [_vm._v("Title")]),
            _c("th", [_vm._v("Type")]),
            _c("th"),
          ]
        ),
      ]),
      _c(
        "tbody",
        _vm._l(_vm.loops, function (loop, i) {
          return _c(
            "tr",
            {
              key: loop._id,
              class:
                "dn-loop-table__row " +
                (_vm.current._id === loop._id ? "active" : ""),
              attrs: { ripple: true },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleLoopClick(loop)
                },
              },
            },
            [
              _c("th", { attrs: { scope: "row" } }, [
                _c("span", [_vm._v(" " + _vm._s(i + 1) + " ")]),
                _c(
                  "span",
                  { staticClass: "dn-loop-table__btn dn-loop-table__play-btn" },
                  [
                    _vm.showPause(loop)
                      ? _c("v-icon", [_vm._v("pause")])
                      : _c("v-icon", [_vm._v("play_arrow")]),
                  ],
                  1
                ),
              ]),
              _c("td", { staticClass: "dn-loop-table__title" }, [
                _vm._v(" " + _vm._s(loop.name) + " "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.$vuetify.breakpoint.smAndDown,
                        expression: "$vuetify.breakpoint.smAndDown",
                      },
                    ],
                    staticClass: "dn-loop-table__subtitle",
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(loop.category ? loop.category.name : "") +
                        " "
                    ),
                  ]
                ),
              ]),
              _c(
                "td",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.$vuetify.breakpoint.mdAndUp,
                      expression: "$vuetify.breakpoint.mdAndUp",
                    },
                  ],
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        loop.category ? loop.category.name : "Pack Sample"
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "td",
                [
                  _vm.canDownload
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "dn-loop-table__btn",
                          attrs: { text: "", icon: "" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleAddLoop(loop)
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("playlist_add")])],
                        1
                      )
                    : _c("div", { staticStyle: { visibility: "hidden" } }, [
                        _vm._v("asdasd"),
                      ]),
                ],
                1
              ),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }